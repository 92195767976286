(function () {
    //get all gallery components
    const galleriesPlus = document.querySelectorAll(".js-gallery-plus");

    //loop trough the galleries and focus on one
    galleriesPlus.forEach((galleryPlus) => {
        const CLASS = {
            prevButton: ".js-prev-slide",
            nextButton: ".js-next-slide",
            actionWrapper: ".js-actions",
            galleryCaptions: ".js-fullscreen-takeover-caption",
            galleryImageWrapper: ".js-fullscreen-takeover-img-wrapper",
            galleryImages: ".js-gallery-images"
        };

        let prev = galleryPlus.querySelectorAll(CLASS.prevButton);
        let next = galleryPlus.querySelectorAll(CLASS.nextButton);
        const imageWrappers = galleryPlus.querySelectorAll(CLASS.galleryImageWrapper);
        const imageCaptions = galleryPlus.querySelectorAll(CLASS.galleryCaptions);
        const images = galleryPlus.querySelectorAll(CLASS.galleryImages);
        const imageCount = images ? images.length : 0;
        let imageIndex = 0;

        var showImage = (imageIndex) => {
            let nextImage = galleryPlus.querySelector(".js-gallery-images[data-index='" + imageIndex + "']");

            //Hide all images and button wrapper as hiding it with opacity-0 isn't enough. We want transition so we hide buttons seperetly
            images.forEach((image) => {
                image.classList.remove("opacity-100", "relative");
                image.classList.add("opacity-0", "absolute");
                image.querySelector(CLASS.actionWrapper).classList.remove("flex");
                image.querySelector(CLASS.actionWrapper).classList.add("hidden");
            });

            //Add necesery classes to the image wrapper and buttons wrapper
            nextImage.classList.remove("opacity-0", "absolute");
            nextImage.classList.add("opacity-100", "relative");
            nextImage.querySelector(CLASS.actionWrapper).classList.remove("hidden");
            nextImage.querySelector(CLASS.actionWrapper).classList.add("flex");
        }

        var nextSlide = () => {
            if (imageIndex !== imageCount - 1) {
                imageIndex++;
            } else {
                imageIndex = 0;
            }
            showImage(imageIndex);
        }

        var previousSlide = () => {
            if (imageIndex !== 0) {
                imageIndex--;
            } else {
                imageIndex = imageCount - 1;
            }

            showImage(imageIndex);
        }

        function fullscreenTakeoverInit() {
            let captions = [];

            imageCaptions.forEach(image => {
                captions.push(image.innerHTML);
            });

            imageWrappers.forEach((wrapper, index) => {
                wrapper.addEventListener('click', () => {
                    window.FullscreenTakeover.openGallery(imageWrappers, index, captions);
                });
            });
        }

        function init() {
            prev.forEach((element) => {
                element.addEventListener("click", (event) => {
                    event.preventDefault();
                    const index = galleryPlus.querySelector(".js-gallery-images[data-index='" + imageIndex + "']").dataset.index;

                    previousSlide(index);
                });
            });

            next.forEach((element) => {
                element.addEventListener("click", (event) => {
                    event.preventDefault();
                    const index = galleryPlus.querySelector(".js-gallery-images[data-index='" + imageIndex + "']").dataset.index;
                    nextSlide(parseInt(index));
                });
            });

            fullscreenTakeoverInit();
        }

        init();
    })
})();